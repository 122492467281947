<template>
    <div >
      
      <div v-if="!viLoader">
      
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="widjet m-2">
              <div class="widjethdr dispflex">
                <div style="width: auto">
                  <span class="iconsect blubg" 
                    ><i class="fas fa-receipt"></i
                  ></span>
                  <h1>Holiday</h1>
                </div>
                <div style="margin-top:10px;margin-left: 50px;"> <input type="text" v-model="searchWords" placeholder="Search Holiday" /> </div>

                <div style="margin-top:10px">
                  <!-- <button style="width: 251px;"
              class="btn web-custbtn web-lgd web-rounded-xl h-100 btn-3"
              id="regbtnid"
              @click="preViewDocument"
            >
            Download Holiday Template
            </button> -->
                  <!-- <a href="https://vidhyaan.sgp1.digitaloceanspaces.com/Holiday.xlsx" target="_blank" >Download Holiday Template </a> -->
                  <!-- <ol> -->
                          <!-- https://vidhyaan.sgp1.digitaloceanspaces.com/Holiday.xlsx -->
                          <!-- <li>
                            <a href="https://vidhyaan.sgp1.digitaloceanspaces.com/new%20file%20import_v1%20%287%29.xlsx" target="_blank" >Sample Student Template </a>
                          
                            <i class="fas fa-times"></i>
                          </li> -->
                        <!-- </ol>  -->
                       </div>
                <div class="mt-1">
                  <div class="upload-btn-wrapper" style="border-radius: 40px;">
                        <!-- <button  class="upbtn" style="margin-left: 0px;margin-right: 13px;" >Upload a file</button> -->
                           <input style="left: 7px;top: 4px;" hidden
                            type="file"
                            id="importExportID"
                            ref="schoolImagechangeFile"
                            name="schoolImagechangeFile"
                            accept=".xls,.xlsx"
                            @change="updateFile('schoolImage','schoolImagechangeFile')"/>
                      </div>
                    </div>
                    <div class="mt-1">
                  <!-- <button class="upbtn">Upload a file</button> -->
                  <!-- Upload Holidays : 
                           <input
                            type="file"
                            id="importExportID"
                            ref="schoolImagechangeFile"
                            name="schoolImagechangeFile"
                            accept=".xls,.xlsx"
                            @change="updateFile('schoolImage','schoolImagechangeFile')"/> -->
                            <!-- <button style="margin-right: 15px;width: 137px;height: 42px;"
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="showDeleteAllModal"
            >
              <span>Delete All</span>
            </button> -->
            <button class="btn gry_bg tooltipt mpopup twoicn"  @click.prevent="downloadFile">
              <i class="fas fa-download"></i>
                        <span class="tooltiptext">Download Holiday Template</span>
                </button>
                <button class="btn gry_bg tooltipt mpopup twoicn"  @click.prevent="uploadbtn">
                 
                  <i class="fas fa-upload"></i>
                        <span class="tooltiptext">Upload</span>
                </button>
            <button class="btn gry_bg tooltipt mpopup twoicn" @click="showAddModal">
                  <i class="fas fa-receipt user1"></i>
                        <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Add</span>
                </button>
            <button  class="btn round red tooltipt" @click="showDeleteAllModal" style="margin-left: 6px;" >
                          <i class="far fa-trash-alt"></i
                          ><span class="tooltiptext">Delete All</span>
                        </button>
            <!-- <button style="margin-right: 15px;width: 137px;height: 42px;"
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="showAddModal"
            >
                <span>Add</span>
            </button> -->
           
                  <!-- <button class="btn gry_bg tooltipt mpopup twoicn" v-b-modal.crtfeeType>
                    <i class="fas fa-receipt user1"></i>
                          <p class="user2"><i class="fas fa-plus"></i></p>
                          <span class="tooltiptext">Add Holiday</span>
                  </button> -->
                </div>
                
              </div>
              <div class="widjetcontent">
              
                  <div class="crtfeeplancont" style="padding: 0 13px">
                    <div
                      class="widjethdr dispflex"
                      style="align-items: baseline; padding: 10px 0"
                    >
                      <!-- <div style="width: auto">
                        <h1 style="margin: 0">Fee Type</h1>
                      </div> -->
                      <!-- <div > <input type="text" v-model="searchWords" placeholder="Search Fee type" /></div>
                      <div class="mr-1">
                       
                        <button
                         
                          class="btn gry_bg tooltipt mpopup twoicn"
                          v-b-modal.crtfeeType
                        >
                          <i class="fas fa-receipt user1"></i>
                          <p class="user2"><i class="fas fa-plus"></i></p>
                          <span class="tooltiptext">Create Fee Type</span>
                        </button>
                      </div> -->
                    </div>
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Holiday Name</div>
                        <div class="cell">Holiday Date</div>
                         <!-- <div class="cell">Last Modified By</div>
                        <div class="cell">Last Modified Date</div> -->
                        <div class="cell">Action</div>
                      </div>
                      <div class="resrow"
                        v-for="(type, index) in holidayList"
                        :key="index"
                      >
                       
                        <div class="cell" data-title="Admin Name">{{ type.name }}</div>
                        <div class="cell" data-title="Admin Name">{{ type.date | dateFormat }}</div>

                        
                         <!-- <div class="cell" data-title="Department Name">

                          {{ type.updatedBy ? type.updatedBy.firstName + " " +  type.updatedBy.lastName : '' }}
                        </div>
                        <div  class="cell" data-title="Role">
                              {{ type.updatedDate | dateFormat }}
                            </div> -->
                        
                        <div class="cell" data-title="Action">
                          <!-- <button
                            @click.prevent="editFeeType(index)"
                            class="btn round orange tooltipt"
                          >
                            <i class="fas fa-edit"></i><span class="tooltiptext">Edit</span>
                          </button> -->
                          <button class="btn round red tooltipt" @click.prevent="showDeleteModal(index)">
                            <i class="far fa-trash-alt"></i
                            ><span class="tooltiptext">Delete</span>
                          </button>
                         
                        </div>
                      
                      </div>
                    </div>
                  </div>
                   <div class="text-center mt-4" v-if="holidayList.length > 0">
                  <div class="pagination" id="pagination">
                    <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
                    <!-- class="active"  -->
                    <a
                      v-for="(item, index) in lastPageNumber"
                      :key="index"
                      v-on:click="selected = item"
                      v-bind:class="{ active: selected == item }"
                      @click="navToSelectedPage(item)"
                      >{{ item }}</a
                    >
                    <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
                  </div>
                </div>
                 <!-- <div class="widjetfooter text-right dk_iconsml mt-3">
                    <button
                      type="button"
                      id="prevBtn"
                      class="btn btnsml"
                     @click="previousRoute"
                    >
                      <i class="fa fa-arrow-left"></i> Back
                    </button>
                  </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
  <div>
       <b-modal
        id="crtfeeType"
        style="width: 100%"
        no-close-on-backdrop
        no-close-on-esc
      >
      <button type="button" class="close"  @click.prevent="closePoup">×</button>

        <div class="contact-form">
          <h2>{{ editId ? 'Edit Holiday' : 'Add Holiday' }}</h2>
          <br />
          <div>
            <form
              data-vv-scope="crtfeeTypeValidate"
              id="crtfeeplans"
              accept-charset="utf-8"
            >
              <div class="form-row">
               
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      v-validate="'required'"
                      type="text"
                      name="fTname"
                      v-model="holidayDetails.name"
                      class="form-control form-input"
                      id="fpnameid"
                    />
                    <!-- <label
                      v-if="errors.first('crtfeeTypeValidate.fTname') || !isFeetypeAvailable"
                      class="vi-error"
                      style="top: -1em"
                      >{{ errors.first('crtfeeTypeValidate.fTname') ? 'Fees Type Name is required ' : 'Fees Type Name already exist. please use a different one.' }} </label
                    > -->
                    <label for="fpnameid"
                      >Holiday Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      v-validate="'required'"
                      type="date"
                      name="fTname"
                      v-model="holidayDetails.date"
                      class="form-control form-input"
                      id="fpnameid"
                    />
                    <!-- <label
                      v-if="errors.first('crtfeeTypeValidate.fTname') || !isFeetypeAvailable"
                      class="vi-error"
                      style="top: -1em"
                      >{{ errors.first('crtfeeTypeValidate.fTname') ? 'Fees Type Name is required ' : 'Fees Type Name already exist. please use a different one.' }} </label
                    > -->
                    <label for="fpnameid"
                      >Holiday Date <span class="required">*</span></label
                    >
                  </span>
                </div>
              </div>
            </form>
  
            <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
             
              <div class="text-center dk_iconsml">
                <button
                 v-if="editId"
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  :disabled="!holidayDetails.name || !holidayDetails.date"
                >
                    <span>Update</span>
                </button>
                
                <button
                v-else
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  :disabled="!holidayDetails.name || !holidayDetails.date"
                  @click.prevent="createHoliday"
                >
                    <span>Save</span>
                </button>
                <button
                  @click.prevent="closePoup"
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
       <b-modal id="deleteFeeTypePopup">
        <!-- <div class="modal-content"> -->
          <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>

        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Delete Confirmation</h2>
          <br />
          Are you sure you want to delete this record?
          <div
            class="pt-3 text-center dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="confirmDelete"
            >
                <span>Confirm</span>
            </button>
            <button @click.prevent="closeDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
                 <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="deleteAllPopup">
        <button type="button" class="close"  @click.prevent="closeDeleteAllPopUp">×</button>

        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Delete Confirmation</h2>
          <br />
          Are you sure you want to delete all?
          <div
            class="pt-3 text-center dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="confirmDeleteAll"
            >
                <span>Confirm</span>
            </button>
            <button @click.prevent="closeDeleteAllPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
                 <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
    </div>
       <vi-spinner
                  v-if="viLoader"
                  text="Loading..."
                  textColor="vi-brand-color"
                  class="flex-fill h-100 vi-fs12"
                  style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
                />
     
    </div>
  </template>
  <script>
  import ViService from "@/services/ViService";
  import secureUI from "../../utils/secureUI";
  import ViSpinner from "../Common/ViSpinner";
  
  
  export default {
    name: "vi-fees-management",
    data() {
      return {
        feeType: {
          feeTypeId: "",
          name: ""
        },
        feesTypeList: [],
        feesTypeListAll: [],
        editId: null,
        isFeetypeAvailable: true,
        preFeeTypeName: '',
        searchWords: '',
        skip: 0, // set the skip info
        limit: 7, // set the limit info
        currentPageNumber: 1,
        lastPageNumber: 1,
        selected: 1,
        countAll: true,
        totalCount: 0,
        viLoader: false,
        holidayDetails: {
            name: '',
            date: null
        },
        holidayList: []
      };
    },
    created() {
      //this.getFeesTypeList();
      this.getHolidayList()
     // this.getFeesTypeListAll()
    },
    computed: {
       styles(){
         var brwHeight = window.innerHeight;
         return{
           height: (brwHeight - 90) + 'px'
         };
        }
       
    },
    watch: {
            searchWords: function (currentVal, oldVal) {
                 let hasCurrentParam = currentVal || '';
                 let hasOldParam = oldVal || '';
  
                if (hasCurrentParam != hasOldParam) {
                     this.skip = 0
                     this.currentPageNumber = 1,
                     this.lastPageNumber = 1,
                     this.selected = 1,
                     this.totalCount = 0,
                             this.countAll = true,
                    this.getHolidayList()
                }
            }
    },
    methods: {
      downloadFile(){
      window.open('https://vidhyaan.sgp1.digitaloceanspaces.com/Holiday.xlsx', "_blank");

    },
    uploadbtn(){
      document.getElementById('importExportID').click();
    },
      async updateFile(type,docType) {
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0]

        const fd = new FormData();

        fd.append('doc', docFileDetails, docFileDetails.name,docFileDetails.actType);
        //this.viLoader = true;

        const response = await ViService.viPutOnly('/document/addHolidays',fd,userData.token);
       
        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getHolidayList()
        } else {
          // this.viLoader = false;
          this.$toasted.error(response.message);
        }
      //  this.viLoader = false;
        document.getElementById("importExportID").value=null; 

      }

    },
    preViewDocument(path) {
      window.open('https://vidhyaan.sgp1.digitaloceanspaces.com/Holiday.xlsx', "_blank");
    },
        previousRoute(){
        let data = localStorage.getItem('previousTab');
        let data2 = localStorage.getItem('activeTab');
       
  
          if(data2 == '/feesManagement/home'){
            localStorage.setItem("activeTab",'/feesManagement/home');
            localStorage.setItem("previousTab",'/feesManagement/home');
          }else{
             localStorage.setItem("activeTab",data);
            localStorage.setItem("previousTab",data);
          }
      
       this.$router.go(-1)
      },
      async navToSelectedPage(selectedPageNo) {
  
        this.skip = (this.limit * (selectedPageNo - 1));
  
        this.countAll = false
  
        this.getHolidayList()
      },
      async goToNextPage() {
        this.countAll = false
        this.currentPageNumber = this.currentPageNumber + 1;
        this.selected = this.currentPageNumber;
        this.skip = (this.limit * (this.currentPageNumber - 1));
  
        this.getHolidayList()
      },
      async goToPreviousPage() {
         this.countAll = false
        this.currentPageNumber = this.currentPageNumber - 1;
        this.selected = this.currentPageNumber;
        this.skip = (this.limit * (this.currentPageNumber - 1));
       
        this.getHolidayList()
      },
      redirectRoute(vipath) {
        if (this.$route.path !== vipath) this.$router.push({ path: vipath });
      },
      closePoup() {
        this.$bvModal.hide("crtfeeType");
        this.holidayDetails.name = ''
        this.holidayDetails.date = null

        this.editId = null
        
      },
      showAddModal(){
        this.$bvModal.show("crtfeeType");

      },
      editFeeType(index){
  
        const feeTypeDetail = this.feesTypeList[index]
        this.editId = feeTypeDetail._id,
        this.feeType.feeTypeId = feeTypeDetail.feeTypeId;
        this.feeType.name = feeTypeDetail.name;
        this.preFeeTypeName = feeTypeDetail.name;
        
        this.$bvModal.show("crtfeeType");
  
      },
      showDeleteModal(index){
  
        const feeTypeDetail = this.holidayList[index]
        this.deleteId = feeTypeDetail._id,
       
        this.$bvModal.show("deleteFeeTypePopup");
  
      },
      closeDeletePopUp(){
        this.$bvModal.hide("deleteFeeTypePopup");
        this.deleteId = null;
      },
       showDeleteAllModal(index){
  
        this.$bvModal.show("deleteAllPopup");
  
      },
      closeDeleteAllPopUp(){
        this.$bvModal.hide("deleteAllPopup");
        //this.deleteId = null;
      },
      
      async confirmDelete() {
        let userData = secureUI.sessionGet("user");
  
        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          this.viLoader = true;
          const response = await ViService.viDelete(`/feesManagement/deleteHoliday/${this.deleteId}`,userData.token);
  
          if (response.isSuccess) {
            this.closeDeletePopUp();
            this.getHolidayList()
           
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                  localStorage.removeItem('user');
                  this.$router.push('/login');
           }
          }
          this.viLoader = false;
        }
      },
      async confirmDeleteAll() {
        let userData = secureUI.sessionGet("user");
  
        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          this.viLoader = true;
          const response = await ViService.viDelete(`/feesManagement/deleteAllHoliday`,userData.token);
  
          if (response.isSuccess) {
            this.closeDeleteAllPopUp();
            this.getHolidayList()
           
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                  localStorage.removeItem('user');
                  this.$router.push('/login');
           }
          }
          this.viLoader = false;
        }
      },
      async checkFeeTypeName() {
           let userData = secureUI.sessionGet("user");
  
          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push("/login");
          } else {
  
          let allowDupUser = true;
  
          if (this.editId) {
            if ( this.feeType.name == this.preFeeTypeName) {
              allowDupUser = false;
            } 
          }
  
          if (allowDupUser) {
            
            const details = this.feesTypeListAll.find((x) => x.name == this.feeType.name);
             if(details){
                this.isFeetypeAvailable = false
             }else{
                this.isFeetypeAvailable = true
             }
  
        
          }
          }
      },
       async getFeesTypeListAll() {
  
        let userData = secureUI.sessionGet("user");
  
        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          this.viLoader = true;
          const response = await ViService.viXGet(
            "/feesManagement/getFeesTypeList",
            userData.token
          );
          if (response.isSuccess) {
            this.feesTypeListAll = secureUI.secureGet(response.data);
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                  localStorage.removeItem('user');
                  this.$router.push('/login');
           }
          }
           this.viLoader = false;
        }
      },
      async getFeesTypeList() {
        let userData = secureUI.sessionGet("user");
  
        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
           const response = await ViService.viXGet(`/feesManagement/getFeesTypeListView?skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}`,userData.token);
         
          if (response.isSuccess) {
  
             const resultData = secureUI.secureGet(response.data);
  
             this.feesTypeList = resultData.feesTypeList
  
            if(this.countAll){
              this.totalCount = resultData.totalCount;
              this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
            }
  
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                  localStorage.removeItem('user');
                  this.$router.push('/login');
           }
          }
        }
      },
      async getHolidayList() {
        let userData = secureUI.sessionGet("user");
  
        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
           const response = await ViService.viXGet(`/feesManagement/getHolidayListView?skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}`,userData.token);
         
          if (response.isSuccess) {
  
             const resultData = secureUI.secureGet(response.data);
  
             this.holidayList = resultData.holidayResult
  
            if(this.countAll){
              this.totalCount = resultData.totalCount;
              this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
            }
  
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                  localStorage.removeItem('user');
                  this.$router.push('/login');
           }
          }
        }
      },
      async createHoliday() {
        // let isFormValid = false;
        // let message = "Please fill all the required details";
  
        // await this.$validator.validateAll("crtfeeTypeValidate").then((result) => {
        //   isFormValid = result;
        // });
  
      //  if (isFormValid && this.isFeetypeAvailable) {
          let userData = secureUI.sessionGet("user");
  
          if (!userData) {
            this.$toasted.error("Please login and do the action");
          } else {
            const response = await ViService.viXPost("/home/createHoliday",this.holidayDetails,userData.token );
  
            if (response.isSuccess) {
              this.closePoup();
              this.getHolidayList();
             // this.getFeesTypeListAll()
              this.$toasted.success(response.message);
  
            //   let getCounts = this.$store.getters.getFeesManagementObjCount
            //   getCounts.feeType = getCounts.feeType + 1
  
            //   this.$store.dispatch('setFeesManagementObjCount', getCounts);
  
            } else {
              this.$toasted.error(response.message);
               if(response.message == 'Your session has expired, please login'){
                  localStorage.removeItem('user');
                  this.$router.push('/login');
           }
            }
          }
        // } else {
        //   this.$toasted.error(message);
  
        // }
      },
       async updateFeeType() {
        let isFormValid = false;
        let message = "Please fill all the required details";
  
        await this.$validator.validateAll("crtfeeTypeValidate").then((result) => {
          isFormValid = result;
        });
  
        if (isFormValid) {
          let userData = secureUI.sessionGet("user");
  
          if (!userData) {
            this.$toasted.error("Please login and do the action");
          } else {
            const response = await ViService.viXPut(`/feesManagement/updateFeeType/${this.editId}`,this.feeType,userData.token );
  
            if (response.isSuccess) {
              this.closePoup();
              this.getFeesTypeList();
              this.getFeesTypeListAll()
              this.$toasted.success(response.message);
            } else {
              this.$toasted.error(response.message);
               if(response.message == 'Your session has expired, please login'){
                  localStorage.removeItem('user');
                  this.$router.push('/login');
           }
            }
          }
        } else {
          this.$toasted.error(message);
        }
      },
    },
    components: {
      ViSpinner
  
    },
  };
  </script>